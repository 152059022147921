import Typography from "@mui/material/Typography";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CustomDataGrid from "src/@core/components/data-grid";
import DialogFormGetInTouchStatus from "../dialog/DialogFormGetInTouchStatus";
import { useState } from "react";

function TableGetInTouch({
    rows,
    totalCount,
    setCurrentPage,
    currentPage,
    setPageSize,
    pageSize,
    loading,
    toggleEdit,
    toggleDelete,
}) {
    const [statusFormDialogOpen, setStatusFormDialogOpen] = useState(false)
    const [statusToEdit, setStatusToEdit] = useState(null)

    const renderCellWithClick = (fieldName) => ({ row }) => (
        <Typography
            noWrap
            variant='body2'
            title={row.fieldName}
            // onClick={() => handleCellClick(row._id)}
            style={{ cursor: 'pointer' }}
        >
            {row[fieldName]}
        </Typography>
    );
    const toggleStatusFormDialog = (e, userToEdit = null) => {
        setStatusFormDialogOpen(prev => !prev)
        setStatusToEdit(userToEdit)
    }
    return (
        <>

            <CustomDataGrid
                loading={loading}
                rowCount={totalCount}
                rows={rows}
                columns={[
                    {
                        field: 'id',
                        flex: 0.2,
                        minWidth: 250,
                        sortable: false,
                        headerName: 'Id',
                        renderCell: renderCellWithClick('_id'),
                    },
                    {
                        field: 'full_name',
                        flex: 0.2,
                        minWidth: 150,
                        sortable: false,
                        headerName: 'Full name',
                        renderCell: renderCellWithClick('full_name'),
                    },
                    {
                        field: 'email',
                        flex: 0.3,
                        minWidth: 150,
                        sortable: false,
                        headerName: 'Email',
                        renderCell: ({ row }) => <Typography noWrap variant='body2' title={row.email}  >
                            {row.email}
                        </Typography>
                    },
                    // {
                    //     field: 'country_code',
                    //     flex: 0.2,
                    //     minWidth: 150,
                    //     sortable: false,
                    //     headerName: 'Country Code',
                    //     renderCell: ({ row }) => <Typography noWrap variant='body2' title={row.country_code}>
                    //         {row.country_code}
                    //     </Typography>
                    // },
                    {
                        field: 'phone_number',
                        flex: 0.2,
                        minWidth: 170,
                        sortable: false,
                        headerName: 'Phone Number',
                        renderCell: ({ row }) => <Typography noWrap variant='body2' title={row.phone_number}>
                            {row.phone_number}
                        </Typography>
                    },
                    {
                        field: 'message',
                        minWidth: 150,
                        flex: 0.4,
                        sortable: false,
                        headerName: 'Message',
                        renderCell: ({ row }) => <Typography noWrap variant='body2' title={row.address} >
                            {row.message}
                        </Typography>
                    },
                    // {
                    //     field: 'actions',
                    //     flex: 0.2,
                    //     minWidth: 170,
                    //     sortable: false,
                    //     headerName: 'Actions',
                    //     renderCell: ({ row }) => <Box display='flex' alignItems='center' gap='10px'>
                    //         <IconButton size="small" color="primary" variant="outlined" onClick={(e) => toggleStatusFormDialog(e, row)}>
                    //             <EditIcon />
                    //         </IconButton>
                    //         <IconButton size="small" color="secondary" onClick={(e) => toggleDelete(e, row)}>
                    //             <DeleteIcon />
                    //         </IconButton>
                    //     </Box>
                    // }
                ]}
                currentPage={currentPage}
                pageSize={pageSize}
                setCurrentPage={setCurrentPage}
                setPageSize={setPageSize}
            />

            <DialogFormGetInTouchStatus
                open={statusFormDialogOpen}
                toggle={toggleStatusFormDialog}
                dataToEdit={statusToEdit}
            // onSuccess={() => {
            //     fetchData()
            // }}

            />
        </>
    )
}

export default TableGetInTouch
