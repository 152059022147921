import { useEffect, useState, useCallback } from "react";
import { Button, Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import PageHeader from "src/@core/components/page-header";
import Translations from "src/layouts/components/Translations";
import { ApiEndPoints } from "src/network/endpoints";
import { DefaultPaginationSettings } from "src/constants/general.const";
import { axiosInstance } from "src/network/adapter";
import { toastError, toastSuccess } from "src/utils/utils";
import TableGetInTouch from "src/views/tables/TableGetInTouch";

const GetInTouch = () => {
    const [loading, setLoading] = useState(false);

    const [getInTouchData, setGetInTouchData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(
        DefaultPaginationSettings.ROWS_PER_PAGE
    );

    const fetchData = ({
        currentPage,
        pageSize = DefaultPaginationSettings.ROWS_PER_PAGE,
        search,
    }) => {
        setLoading(true);
        let params = {
            pages: currentPage,
            limits: pageSize,
            search: search,
        };
        if (params.pages === undefined && params.search === undefined) return;

        axiosInstance
            .get(ApiEndPoints.GetInTouch.list, { params })
            .then((response) => {
                setGetInTouchData(response.data.data);
                setTotalCount(response.data.data.totalData);
                console.log("Contact_List response--------------------", response);
            })
            .catch((error) => {
                toastError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchData({
            currentPage: currentPage,
            pageSize: pageSize,
        });
    }, [currentPage, pageSize]);

    return (
        <>
            <Grid container spacing={4} className="match-height">
                <PageHeader
                    title={
                        <Typography variant="h5">
                            <Translations text="Get In Touch" />
                        </Typography>
                    }
                // action={
                //     <Button variant="contained" onClick={toggleContactFormDialog}>
                //         Add Contact
                //     </Button>
                // }
                />
                <Grid item xs={12}>
                    <Card>
                        <Box
                            sx={{
                                p: 5,
                                pb: 0,
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <Box></Box>
                        </Box>
                        <Box>
                            <Box sx={{ p: 5 }}>
                                <TableGetInTouch
                                    loading={loading}
                                    rows={getInTouchData}
                                    totalCount={totalCount}
                                    setCurrentPage={setCurrentPage}
                                    currentPage={currentPage}
                                    setPageSize={setPageSize}
                                    pageSize={pageSize}
                                // toggleEdit={toggleContactFormDialog}
                                // toggleDelete={toggleConfirmationDialog}
                                />
                            </Box>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}
export default GetInTouch