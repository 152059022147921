// ** Icon imports
import HomeIcon from "mdi-material-ui/Home";
import StorefrontIcon from "@mui/icons-material/Storefront";
import ViewListIcon from "@mui/icons-material/ViewList";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import CallIcon from "@mui/icons-material/Call";
import SocialDistanceIcon from "@mui/icons-material/SocialDistance";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LoopOutlinedIcon from "@mui/icons-material/LoopOutlined";
import DescriptionIcon from "@mui/icons-material/Description";
import ReceiptIcon from "@mui/icons-material/Receipt";

const navigation = () => {
  return [
    {
      title: "Dashboard",
      icon: HomeIcon,
      path: "/",
    },
    // {
    //   sectionTitle: "Knowledge Base",
    // },
    {
      title: "Restaurant",
      icon: StorefrontIcon,
      path: "/restaurant",
    },
    {
      title: "Menu",
      icon: ViewListIcon,
      path: "/category",
    },
    {
      title: "Contact Us",
      icon: CallIcon,
      path: "/contactUs",
    },
    {
      title: "Delivery",
      icon: DeliveryDiningIcon,
      path: "/delivery",
    },
    {
      title: "TAX",
      icon: CurrencyRupeeIcon,
      path: "/gst",
    },
    {
      title: "Delivery Range",
      icon: SocialDistanceIcon,
      path: "/deliveryRange",
    },
    {
      title: "Privacy Policy",
      icon: LockOutlinedIcon,
      path: "/privacyPolicy",
    },
    {
      title: "Return & Refund",
      icon: LoopOutlinedIcon,
      path: "/returnRefund",
    },
    {
      title: "Terms & Services",
      icon: DescriptionIcon,
      path: "/termsServices",
    },
    {
      title: "Delivery Partner",
      icon: ReceiptIcon,
      path: "/deliveryPartner",
    },
  ];
};

export default navigation;
