import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, IconButton, Radio, RadioGroup, TextField } from "@mui/material"
import CloseIcon from "mdi-material-ui/Close";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { CleaveNumberInput } from "src/@core/components/cleave-components";
import { axiosInstance } from "src/network/adapter";
import { ApiEndPoints } from "src/network/endpoints";
import * as yup from 'yup'
import { toastError, toastSuccess } from "src/utils/utils";
import "react-datepicker/dist/react-datepicker.css";

const validationSchema = yup.object().shape({
    name: yup.string().required("Required"),
    description: yup.string().nullable(),
    price: yup.number()
        .typeError("Must be  number only")
});

const DialogFormAddMenuRestaurant = (props) => {

    const { mode, open, toggle, dataToEdit, onSuccess } = props;

    const [loading, setLoading] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({
        defaultValues: {
            name: '',
            description: '',
            price: '',
            item_images: '',
            veg_or_nonVeg: ''
        },
        resolver: yupResolver(validationSchema),
        mode: 'onChange'
    })

    useEffect(() => {
        if (open) {
            setLoading(false);
            reset({
                name: dataToEdit?.name || '',
                description: dataToEdit?.description || '',
                price: dataToEdit?.price || '',
                item_images: dataToEdit?.item_images,
                veg_or_nonVeg: dataToEdit?.is_veg === 'veg' ? 'veg' : 'non-veg' || '' // Set default value based on is_veg
            })
            setDialogTitle(mode === "add" ? "Add Restaurant Menu" : "Edit Restaurant Menu")
        }
    }, [dataToEdit, mode, open, reset])

    const onSubmit = (data) => {
        let payload = {
            catagoryId: data.catagoryId,
            name: data.name,
            description: data.description,
            price: data.price,
            item_images: ["a.jpg"],
            is_non_veg: data.veg_or_nonVeg === 'non-veg',
            is_veg: data.veg_or_nonVeg === 'veg',
        }
        setLoading(true);

        let apiInstance = null;

        if (mode === "edit") {
            apiInstance = axiosInstance
                .patch(ApiEndPoints.Restaurant_Menu.edit(dataToEdit._id), payload)
        } else {
            apiInstance = axiosInstance
                .post(ApiEndPoints.Restaurant_Menu.create, payload)
        }
        apiInstance
            .then((response) => response.data)
            .then((response) => {
                onSuccess();
                toastSuccess(response.message);
                toggle();
            })
            .catch((error) => {
                toastError(error)
            })
            .finally(() => {
                setLoading(false);
            })
    }
    console.log("submitted", dataToEdit);

    return <>
        <Dialog open={open} onClose={toggle} fullWidth maxWidth='sm' scroll="paper">
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>{dialogTitle}</Box>
                <IconButton
                    aria-label="close"
                    onClick={toggle}
                    sx={{ color: (theme) => theme.palette.grey[500] }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ pb: 8, px: { sx: 8, sm: 15 }, pt: { xs: 8, sm: 12.5 } }}>
                <form id="restaurant-form" onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <FormLabel htmlFor='name' error={Boolean(errors.name)}>Name</FormLabel>
                                <Controller
                                    name='name'
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                        <TextField
                                            value={value}
                                            onChange={onChange}
                                            type="text"
                                            placeholder='Enter Restaurant Name'
                                            error={Boolean(errors.name)}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    )}
                                />
                                {errors.name && (
                                    <FormHelperText sx={{ color: 'error.main' }}>
                                        {errors.name.message}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <FormLabel htmlFor='description' error={Boolean(errors.description)}>Description</FormLabel>
                                <Controller
                                    name='description'
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                        <TextField
                                            value={value}
                                            onChange={onChange}
                                            type="text"
                                            placeholder='Enter Description'
                                            error={Boolean(errors.description)}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    )}
                                />
                                {errors.description && (
                                    <FormHelperText sx={{ color: 'error.main' }}>
                                        {errors.description.message}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <FormLabel htmlFor='price' error={Boolean(errors.price)}>Set Price</FormLabel>
                                <Controller
                                    name='price'
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                        <TextField
                                            value={value}
                                            onChange={onChange}
                                            type="text"
                                            placeholder='Enter Price'
                                            error={Boolean(errors.price)}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    )}
                                />
                                {errors.price && (
                                    <FormHelperText sx={{ color: 'error.main' }}>
                                        {errors.price.message}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <FormLabel htmlFor='item_images' error={Boolean(errors.item_images)}>Item Images</FormLabel>
                                <Controller
                                    name='item_images'
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                        <TextField
                                            value={value}
                                            onChange={onChange}
                                            type="text"
                                            placeholder='Select Image'
                                            error={Boolean(errors.item_images)}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    )}
                                />
                                {errors.item_images && (
                                    <FormHelperText sx={{ color: 'error.main' }}>
                                        {errors.item_images.message}
                                    </FormHelperText>
                                )}
                            </FormControl>

                        </Grid>
                        <Grid item xs={12}>
                            <FormControl component="fieldset" fullWidth>
                                <FormLabel component="legend">Veg or Non-veg</FormLabel>
                                <Controller
                                    name='veg_or_nonVeg'
                                    control={control}
                                    defaultValue=""
                                    render={({ field: { value, onChange } }) => (
                                        <RadioGroup value={value} onChange={onChange}>
                                            <FormControlLabel value="veg" control={<Radio />} label="Veg" />
                                            <FormControlLabel value="non-veg" control={<Radio />} label="Non-veg" />
                                        </RadioGroup>
                                    )}
                                />
                                {errors.veg_or_nonVeg && (
                                    <FormHelperText sx={{ color: 'error.main' }}>
                                        {errors.veg_or_nonVeg.message}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    size="large"
                    type="submit"
                    form="restaurant-form"
                    variant="contained"
                    loading={loading}
                >
                    Submit
                </LoadingButton>
                <Button size="large" variant="outlined" onClick={toggle}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog >
    </>
}

export default DialogFormAddMenuRestaurant