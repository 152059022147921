// ** Icon imports
import HomeIcon from "mdi-material-ui/Home";
import StorefrontIcon from "@mui/icons-material/Storefront";
import ViewListIcon from "@mui/icons-material/ViewList";
import CallIcon from "@mui/icons-material/Call";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import SocialDistanceIcon from "@mui/icons-material/SocialDistance";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LoopOutlinedIcon from "@mui/icons-material/LoopOutlined";
import DescriptionIcon from "@mui/icons-material/Description";
import ReceiptIcon from "@mui/icons-material/Receipt";
import BurstModeIcon from '@mui/icons-material/BurstMode';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import CommentIcon from '@mui/icons-material/Comment';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import EuroIcon from '@mui/icons-material/Euro';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import CategoryIcon from '@mui/icons-material/Category';
const navigation = () => {
  return [
    {
      title: "Dashboard",
      icon: HomeIcon,
      path: "/",
    },
    // {
    //   sectionTitle: "Knowledge Base",
    // },
    {
      title: "Restaurant",
      icon: StorefrontIcon,
      path: "/restaurant",
    },
    {
      title: "Restaurant Tags",
      icon: CategoryIcon,
      path: "/restaurant-tags",
    },
    {
      title: "Menu",
      icon: ViewListIcon,
      path: "/category",
    },
    {
      title: "Contact Us",
      icon: CallIcon,
      path: "/contactUs",
    },
    {
      title: "Delivery Fees",
      icon: DeliveryDiningIcon,
      path: "/delivery",
    },
    {
      title: "TAX",
      icon: EuroIcon,
      path: "/gst",
    },
    {
      title: "Delivery Range",
      icon: SocialDistanceIcon,
      path: "/deliveryRange",
    },
    {
      title: "Privacy Policy",
      icon: LockOutlinedIcon,
      path: "/privacyPolicy",
    },
    {
      title: "Return & Refund",
      icon: LoopOutlinedIcon,
      path: "/returnRefund",
    },
    {
      title: "Terms & Services",
      icon: DescriptionIcon,
      path: "/termsServices",
    },
    {
      title: "Delivery Partner",
      icon: ReceiptIcon,
      path: "/deliveryPartner",
    },
    {
      title: "Sliders",
      icon: BurstModeIcon,
      path: "/sliders",
    },
    {
      title: "Orders",
      icon: ShoppingCartIcon,
      path: "/orders",
    },
    {
      title: "Promo Code",
      icon: LocalOfferIcon,
      path: "/promo-code",
    },
    {
      title: "Vendor Support Tools",
      icon: ManageAccountsIcon,
      path: "/vendor-support-tools",
    },
    {
      title: "Customer Support Tools",
      icon: SupportAgentIcon,
      path: "/customer-support-tools",
    },
    {
      title: "Feedback and Ratings",
      icon: CommentIcon,
      path: "/feedback-ratings",
    },
    {
      title: "Notification and Alerts",
      icon: NotificationsActiveIcon,
      path: "/notification-alerts",
    },
    // {
    //   title: "Analytics",
    //   icon: AnalyticsIcon,
    //   path: "/analytics",
    // },
    {
      title: "Get In Touch",
      icon: ConnectWithoutContactIcon,
      path: "/get-in-touch",
    },
  ];
};

export default navigation;
