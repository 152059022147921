import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, IconButton, Radio, RadioGroup, TextField } from "@mui/material"
import CloseIcon from "mdi-material-ui/Close";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { CleaveNumberInput } from "src/@core/components/cleave-components";
import { axiosInstance } from "src/network/adapter";
import { ApiEndPoints } from "src/network/endpoints";
import * as yup from 'yup'
import { toastError, toastSuccess } from "src/utils/utils";
import "react-datepicker/dist/react-datepicker.css";
import CustomFileUploads from "../common/customFileUploads";

const validationSchema = yup.object().shape({
    tag_name: yup.string().required("Required"),
});

const DialogRestaurantTags = (props) => {

    const { mode, open, toggle, dataToEdit, onSuccess } = props;

    const [loading, setLoading] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');
    const {
        control,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({
        defaultValues: {
            tag_name: '',
            tag_images: null,
        },
        resolver: yupResolver(validationSchema),
        mode: 'onChange'
    })

    useEffect(() => {
        if (open) {
            setLoading(false);
            reset({
                tag_name: dataToEdit?.tag_name || '',
                tag_images: dataToEdit?.tag_images || null,
            })
            setDialogTitle(mode === "add" ? "Add Restaurant Tags" : "Edit Restaurant Tags")
        }
    }, [dataToEdit, mode, open, reset])



    const onSubmit = (data) => {
        const payload = new FormData();
        payload.append('tag_name', data.tag_name);
        if (data.tag_images && data.tag_images instanceof File) {
            payload.append('tag_images', data.tag_images);
        }

        setLoading(true);

        let apiInstance = null;

        if (mode === "edit") {
            apiInstance = axiosInstance
                .patch(ApiEndPoints.Restaurant_Tags.edit(dataToEdit._id), payload, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
        } else {
            apiInstance = axiosInstance
                .post(ApiEndPoints.Restaurant_Tags.add, payload, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
        }
        apiInstance
            .then((response) => response.data)
            .then((response) => {
                onSuccess();
                toastSuccess(response.message);
                toggle();
            })
            .catch((error) => {
                toastError(error)
            })
            .finally(() => {
                setLoading(false);
            })
    }
    console.log("submitted", dataToEdit);

    return <>
        <Dialog open={open} onClose={toggle} fullWidth maxWidth='sm' scroll="paper">
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>{dialogTitle}</Box>
                <IconButton
                    aria-label="close"
                    onClick={toggle}
                    sx={{ color: (theme) => theme.palette.grey[500] }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ pb: 8, px: { sx: 8, sm: 15 }, pt: { xs: 8, sm: 12.5 } }}>
                <form id="restaurant-form" onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <FormLabel htmlFor='tag_name' error={Boolean(errors.tag_name)}>Tag Name</FormLabel>
                                <Controller
                                    name='tag_name'
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                        <TextField
                                            value={value}
                                            onChange={onChange}
                                            type="text"
                                            placeholder='Enter Restaurant Tag'
                                            error={Boolean(errors.tag_name)}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    )}
                                />

                                {errors.tag_name && (
                                    <FormHelperText sx={{ color: 'error.main' }}>
                                        {errors.tag_name.message}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <FormLabel htmlFor='tag_images' error={Boolean(errors.tag_images)}>Tag Images</FormLabel>
                                <Controller
                                    name='tag_images'
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                        <CustomFileUploads
                                            multiple={false}
                                            subtitle="(Max file size 3mb)"
                                            minHeight="100px"
                                            files={value}
                                            onChange={onChange}
                                            title={"Add Image"}
                                        // MediaUrl={MEDIA_URL}
                                        />
                                    )}
                                />
                                {errors.tag_images && (
                                    <FormHelperText sx={{ color: 'error.main' }}>
                                        {errors.tag_images.message}
                                    </FormHelperText>
                                )}
                            </FormControl>

                        </Grid>

                    </Grid>
                </form>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    size="large"
                    type="submit"
                    form="restaurant-form"
                    variant="contained"
                    loading={loading}
                >
                    Submit
                </LoadingButton>
                <Button size="large" variant="outlined" onClick={toggle}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog >
    </>
}

export default DialogRestaurantTags